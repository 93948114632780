document.querySelectorAll(".clicker").forEach(function (el) {
  const link = el.querySelector("a");

  if (!link) return;

  el.style = "cursor: pointer;";

  el.addEventListener("click", function (e) {
    if (e.target.tagName === "A") return;
    link.click();
  });
});
